import { useEffect, useState } from 'react'
import MiniCarousel from '../ui/mini-carousel/mini-carousel'
import ReviewCard, { ReviewCardProps } from './review-card'

export interface ReviewListProps {
  reviews: ReviewCardProps[]
}

const ReviewFeed = ({ reviews }: ReviewListProps) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
  const [lastHoveredIndex, setLastHoveredIndex] = useState<number | null>(null)
  const [currentWidth, setCurrentWidth] = useState<number>(1000) // if "window" is not ready, render 3 cards on desktop

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCurrentWidth(window.innerWidth)
      const handleResize = () => setCurrentWidth(window.innerWidth)
      window.addEventListener('resize', handleResize)

      return () => window.removeEventListener('resize', handleResize)
    }
  }, [])

  // Define breakpoints and corresponding review limits
  const getMaxReviews = (width: number) => {
    if (width < 1024) return 3 // md
    if (width < 1280) return 4 // lg and xl
    return 5 // 2xl and above
  }
  // Filter reviews based on the current width
  const maxReviews = getMaxReviews(currentWidth)
  const filteredReviews = reviews.slice(0, maxReviews)

  const getCardStyle = (index: number) => {
    const baseRotation = index % 2 === 0 ? 'md:-rotate-2' : 'md:rotate-2'
    const hoverRotation = index % 2 === 0 ? 'md:-rotate-3' : 'md:rotate-3'
    const baseTranslate = index % 2 === 0 ? 'md:translate-y-2' : ''

    return `${baseRotation} hover:${hoverRotation} ${baseTranslate}`
  }

  const getCardInteractionStyles = (index: number) => {
    const isHovered = hoveredIndex === index
    const isLastHovered = lastHoveredIndex === index

    const baseStyles = 'md:-ml-10 transition-all duration-300'
    const shadowStyles = isHovered ? 'shadow-xl' : 'shadow-md'
    const hoverTranslate = 'md:hover:-translate-y-7'

    const filterStyle =
      hoveredIndex !== null && !isHovered ? 'md:brightness-90 md:grayscale-[15%]' : 'brightness-100 grayscale-0'

    let zIndexStyle = 'z-10'
    if (isHovered || isLastHovered) {
      zIndexStyle = 'z-50'
    }

    return `${baseStyles} ${shadowStyles} ${hoverTranslate} ${filterStyle} ${zIndexStyle}`
  }

  return (
    <>
      {/* Mobile */}
      <div className="flex w-full gap-x-3 overflow-auto md:hidden">
        <MiniCarousel
          slides={reviews.map((review) => (
            <ReviewCard key={review.link} {...review} />
          ))}
          id="customersReviews"
        />
      </div>

      {/* Desktop */}
      <div className="relative z-0 flex w-full max-md:hidden">
        {filteredReviews.map((review, index) => (
          <span
            className={`${getCardInteractionStyles(index)} ${getCardStyle(index)} rounded-2xl`}
            key={index}
            onMouseEnter={() => {
              setHoveredIndex(index)
              setLastHoveredIndex(index)
            }}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <ReviewCard {...review} />
          </span>
        ))}
      </div>
    </>
  )
}

export default ReviewFeed
