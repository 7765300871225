import WomanWalkingDog from '@/images/illustrations/womanWalkingDog.png'
import Cyclist from '@/images/illustrations/cyclist.png'
import ChildrenPlaying from '@/images/illustrations/childrenPlaying.png'
import OlderManWokingOut from '@/images/illustrations/olderManWorkingOut.png'
import * as UI from '@/ui'

type Statistic = {
  title: string
  description: string
}

export type StatsBlockProps = {
  title: string
  content: string
  statistics: Statistic[]
}

export const StatsBlock = () => {
  return (
    <UI.Block gap="xl">
      <UI.Block>
        <UI.Heading
          as="h2"
          size={{ default: 'medium', lg: 'large' }}
          color="black"
          className="w-full self-stretch md:max-w-md"
        >
          {statsData.title}
        </UI.Heading>

        <UI.Paragraph className="max-w-xl" size={{ default: 'medium', lg: 'large' }}>
          {statsData.content}
        </UI.Paragraph>
      </UI.Block>

      <div className="relative z-0 grid w-full grid-cols-1 md:grid-cols-4 md:items-end md:pl-12 lg:pl-40">
        <div className="relative z-10 order-3 flex h-[144px] flex-col items-start gap-2 rounded-3xl bg-selphAmber-500 p-6 shadow-[0px_0px_16px_0px_rgba(0,0,0,0.15)] max-md:mr-36 max-md:-mb-4 md:order-1 md:-mr-4 md:h-[312px] md:rounded-2xl">
          <UI.Paragraph size="xxl" className="text-selphWhite-300" weight="regular">
            {statsData.statistics[0].title}
          </UI.Paragraph>
          <UI.Badge size="medium" color="none" className="bg-selphAmber-400 text-selphAmber-100">
            {statsData.statistics[0].description}
          </UI.Badge>
          <UI.Image
            width={176}
            priority
            src={WomanWalkingDog}
            alt="Woman walking dog illustration"
            className="absolute -bottom-8 -left-18 h-auto shrink-0 max-md:hidden"
          />
        </div>

        <div className="relative z-20 order-2 flex h-[144px] flex-col items-start gap-2 rounded-3xl bg-selphTeal-500 p-6 shadow-[0px_0px_16px_0px_rgba(0,0,0,0.15)] max-md:mr-24 max-md:-mb-4 md:order-2 md:-mr-4 md:h-[354px] md:rounded-2xl">
          <UI.Paragraph size="xxl" className="text-selphWhite-300" weight="regular">
            {statsData.statistics[1].title}
          </UI.Paragraph>
          <UI.Badge size="medium" color="none" className="bg-selphTeal-400 text-selphAmber-100">
            {statsData.statistics[1].description}
          </UI.Badge>
          <UI.Image
            width={174}
            priority
            src={Cyclist}
            alt="Cyclist illustration"
            className="absolute -bottom-8 -left-11 h-auto shrink-0 max-md:hidden"
          />
          <UI.Image
            width={190}
            priority
            src={OlderManWokingOut}
            alt="Older man working out illustration"
            className="absolute -right-10 -bottom-40 z-50 h-auto shrink-0 md:hidden"
          />
        </div>

        <div className="relative z-30 order-1 flex h-[144px] flex-col items-start gap-2 rounded-3xl bg-selphOrange-500 p-6 shadow-[0px_0px_16px_0px_rgba(0,0,0,0.15)] max-md:mr-12 max-md:-mb-4 md:order-3 md:-mr-4 md:h-[412px] md:rounded-2xl">
          <UI.Paragraph size="xxl" className="text-selphWhite-300" weight="regular">
            {statsData.statistics[2].title}
          </UI.Paragraph>
          <UI.Badge size="medium" color="none" className="bg-selphOrange-400 text-selphAmber-100">
            {statsData.statistics[2].description}
          </UI.Badge>
          <UI.Image
            width={205}
            priority
            src={ChildrenPlaying}
            alt="Children playing illustration"
            className="absolute -bottom-12 -left-11 h-auto shrink-0 max-md:hidden"
          />
        </div>

        <div className="relative z-40 order-0 flex h-[144px] flex-col items-start gap-2 rounded-3xl bg-selphGreen-500 p-6 shadow-[0px_0px_16px_0px_rgba(0,0,0,0.15)] max-md:-mb-4 md:order-4 md:-mr-4 md:h-[463px] md:rounded-2xl">
          <div className="flex">
            <UI.Paragraph size="xxl" className="self-baseline text-selphWhite-300" weight="regular">
              {statsData.statistics[3].title}
            </UI.Paragraph>
          </div>
          <UI.Badge size="medium" color="none" className="bg-selphGreen-400 text-selphAmber-100">
            {statsData.statistics[3].description}
          </UI.Badge>
          <UI.Image
            width={169}
            priority
            src={OlderManWokingOut}
            alt="Older man working out illustration"
            className="absolute -bottom-2 -left-8 h-auto shrink-0 max-md:hidden"
          />
        </div>
      </div>
    </UI.Block>
  )
}

export default StatsBlock

const statsData: StatsBlockProps = {
  title: "We've got the stats to back it all up.",
  content:
    'When it comes to health, you want to know you’re in safe hands. We’ve got decades of experience, and we’ve got the stats to prove it.',
  statistics: [
    {
      title: '20+',
      description: 'Years in healthcare',
    },
    {
      title: '65,000+',
      description: '5 star reviews',
    },
    {
      title: '1 million+',
      description: 'Happy customers',
    },
    {
      title: '2 million+',
      description: 'Tests reported',
    },
  ],
}
