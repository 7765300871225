import words from 'lodash-es/words'
import trim from 'lodash-es/trim'
import FiveStarsIcon from '/public/images/tp_rating_5-star.svg?svgr'
import { fromIsoString } from '../../helpers/date'
import * as UI from '@/ui'

export type ReviewCardProps = {
  link: string
  author: string
  date: string
  content: string
}

export const ReviewCard = ({ link, author, date, content }: ReviewCardProps) => {
  const isoDate = fromIsoString(date)
  const reviewDate = `${isoDate.day}${getOrdinalSuffix(isoDate.day)} ${isoDate.toFormat('MMMM yyyy')}`

  return (
    <UI.Link
      to={link}
      target="_blank"
      highlight="none"
      color="none"
      className="block h-full overflow-hidden rounded-2xl border-1 border-selphGreen-500"
    >
      <UI.Block
        className="flex h-[325px] w-[308px] justify-between rounded-2xl bg-selphGreen-400 p-8 max-md:px-5 max-md:py-6 md:w-[296px]"
        display="flexCol"
      >
        <div className="flex items-center gap-3">
          <div className="flex min-h-12 min-w-12 items-center justify-center rounded-full bg-selphAmber-500 pb-1">
            <span color="white" className="text-2xl font-semibold text-selphWhite-500">
              {getInitials(author)}
            </span>
          </div>

          <UI.Block display="flexCol" gap="xs" className="text-start">
            <UI.Paragraph color="white" size="xl" className="line-clamp-1 font-normal">
              {author}
            </UI.Paragraph>
            <time
              dateTime={isoDate.toISO() || ''}
              className="line-clamp-1 text-lg leading-6 text-selphWhite-500 md:leading-5"
            >
              {reviewDate}
            </time>
          </UI.Block>
        </div>

        <UI.Paragraph color="white" className="line-clamp-5 text-left leading-6">
          &ldquo;{content}&rdquo;
        </UI.Paragraph>

        <FiveStarsIcon width="120" />
        <span className="sr-only">Five Star Review</span>
      </UI.Block>
    </UI.Link>
  )
}

export default ReviewCard

const getInitials = (author: string) => {
  // Define titles to ignore
  const titles = ['mr', 'mrs', 'ms', 'miss', 'dr']
  // Extract words and filter out titles
  const names = words(trim(author)).filter((name) => !titles.includes(name.toLowerCase()))

  if (names.length === 0) return 'cu' // default for empty name
  if (names.length === 1) return names[0].slice(0, 2).toLowerCase()

  return (names[0][0] + names[1][0]).toLowerCase()
}

const getOrdinalSuffix = (day: number): string => {
  if (day > 3 && day < 21) return 'th'
  switch (day % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}
