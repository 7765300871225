import ReviewFeed, { ReviewListProps } from '../review/review-feed'
import TrustpilotBox from '../Trustpilot/trustpilot-box'
import * as UI from '@/ui'

export const ProductReviews = ({ reviews }: ReviewListProps) => {
  return (
    <UI.Block gap="large" display="flexCol" id="product-review-block" className="relative px-4 py-16 sm:px-6">
      <UI.Badge size="medium" color="outline" className="mx-auto text-selphWhite-500">
        Customer Reviews
      </UI.Badge>

      <UI.Heading
        as="h2"
        color="white"
        weight="regular"
        size={{ default: 'medium', md: 'large' }}
        className="mx-auto max-w-xl text-center lg:max-w-2xl"
      >
        Don&apos;t just take our word for it
      </UI.Heading>

      <UI.Paragraph color="white" size={{ default: 'medium', md: 'large' }} className="text-center">
        At Selph, we&apos;ve helped thousands of people feel better today and live longer tomorrow.
      </UI.Paragraph>

      <div className="mx-auto my-10 text-center max-md:w-full md:pl-5">
        <ReviewFeed reviews={reviews} />
      </div>

      <div className="mx-auto text-center">
        <TrustpilotBox boxStyle="microHeader" darkBg />
      </div>
    </UI.Block>
  )
}

export default ProductReviews
